<template>
  <div>
    <courier-configuration :openModal="openModalBlocked" :openModalAdd="openModalAdd" ref="tableCourrierConfi"/>
    <modal-courier-configuration-blocked :formTab="form" @filter="filter"/>
    <modal-courier-configuration-add  />
  </div>
</template>

<script>

import CourierConfiguration from './CouriersConfiguration.vue'
import ModalCourierConfigurationAdd from './ModalCourierConfigurationAdd.vue'
import ModalCourierConfigurationBlocked from './ModalCourierConfigurationBlocked.vue'

export default {
  name: 'tabsForPlatform',
  components: {
    CourierConfiguration,
    ModalCourierConfigurationBlocked,
    ModalCourierConfigurationAdd
  },
  data () {
    return {
      form: {},
      tabIndex: 0,
      loading: {
        first: true
      }
    }
  },
  methods: {
    openModalBlocked(data) {
      this.form = { ...data }
      this.$bvModal.show('ModalCourierConfigurationBlocked')
    },
    openModalAdd() {
      this.$bvModal.show('ModalCourierConfigurationAdd')
    },
    filter() {
      this.$refs.tableCourrierConfi.getInitialData()
    }
  }
}
</script>
