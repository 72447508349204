<template>
  <b-modal
    id="ModalCourierConfigurationAdd"
    :title="'Agregar Courier a la empresa'"
    size="sm"
    @close="onClose"
    @show="onShow"
    hide-footer
    no-close-on-backdrop
  >
      <form-render
        class="mb-2"
        :fields.sync="fields"
        :form.sync="form"
        @send="onAccept"
        :key="keyFormRender"
        ref="formRenderAccount"
        containerButtonsClass="col-sm-12 col-lg-4 container-button"
      >
      </form-render>
      <b-button @click="ok" variant="warning" :disabled="loading">
        <b-spinner v-show="loading" small></b-spinner> Agregar
      </b-button>
  </b-modal>
</template>

<script>
import CarriersService from '@/views/modules/configuration/carriers/carriers.service'
import BaseServices from '@/store/services/index'
import {mapGetters} from 'vuex'
export default {
  name: 'ModalWarehouseCompanies',
  props: ['formTab'],
  data() {
    return {
      form: {},
      fields: [],
      editing: false,
      keyFormRender: 0,
      CarrierService: new CarriersService(this),
      baseService: new BaseServices(this),
      parenIdOptions: [
        { id: 10, text: 'Distribución' },
        { id: parseInt(this.$route.params.id), text: 'Pasarela' }
      ],
      optionsCourrier: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      companyInformation: 'getCompanyInformation',
      carrierListByCompany: 'getCarrierListByCompany'
    })
    
  },
  watch: {
    formTab() {
      this.initializeForm()
    },
    carrierListByCompany() {
      this.optionsCourrier = this.carrierListByCompany
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    onShow() {      
      this.setInitialData()
    },
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', name: 'carrier_id', label: 'Courier', validation: 'required', placeholder: 'Seleccione courier', containerClass: 'col-12', clearable: true }
      ]
      this.form.parent_id =  this.parenIdOptions[0]
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role) && !this.companyInformation?.rows?.company?.marketplace_id) {
        this.fields.unshift({ fieldType: 'FieldSelect', name: 'parent_id', label: 'Tipo de contrato', placeholder: 'Seleccione el tipo de contrato', containerClass: 'col-12 mb-2 container', options: this.parenIdOptions})
        this.showFilters = true
      }
      
      this.fields.find(field => field.name === 'carrier_id').options = this.optionsCourrier
    },
    initializeForm() {
      this.editing = !!this.formTab.id
      if (this.editing) {
        this.form = {
          ...this.formTab
        }
      } else {
        this.form = {}
      }
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderAccount.checkForm()
    },
    onAccept(form) {
      this.loading = true
      const isMKP = this.companyInformation?.rows?.company?.marketplace_id
      this.$store.dispatch('fetchService', { 
        name: 'createCourierConfiguration',
        params: { company_id: parseInt(this.$route.params.id) },
        queryParams: { parent_id: isMKP ? isMKP : form.parent_id.id, carrier_id: form.carrier_id.id },
        onSuccess: this.onAddCourierCompanySuccess, onError: () => this.loading = false
      })
    },
    onAddCourierCompanySuccess() {
      this.$bvModal.hide('ModalCourierConfigurationAdd')
      this.$success(this.$t('msg-exito-guardar'))

      const queryParams = {
        exclude_flash : 0
      }
      const params = {
        company_id: parseInt(this.$route.params.id)
      }
      this.$store.dispatch('fetchService', { 
        name: 'getCourierConfiguration',
        queryParams, params,
        onSuccess: () => this.loading = false,
        onError: () => this.loading = false
      })
    },
    onClose() {
      this.form = {}
      this.editing = false
      this.keyFormRender++
      this.codeForServices = [{}]
      this.showTemplate = false
    }
  }
}
</script>


<style scoped lang="scss">

.container{
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
  width: 100%;
  padding: 2rem;
}


.logo {
  width: 200px;
  height: 200px;
  object-fit: fill;
}

.content-caracteristics {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1rem;
}

.row-carcteristics {
  display: flex;
  flex-direction: column;
}
.service-row {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.label {
  font-weight: bold;
  font-size: 1rem;
}

.value {
  font-weight: normal;
}

.service-contain{
  display: flex;
  flex-direction: row;
}

.service {
  background-color: var(--dark-3);
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px; /* Ajustar según el diseño */
}
</style>
