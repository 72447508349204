<template>
  <b-modal
    id="ModalCourierConfigurationBlocked"
    :title="'Bloquear y desbloquear servicios'"
    modal-class="dialog-900"
    @close="onClose"
    ok-only
    no-close-on-backdrop
    hide-footer
  >

    <form-render
        class="mb-2"
        :fields.sync="fields"
        :form.sync="form"
        @send="onAccept"
        :key="keyFormRender"
        ref="formRenderAccount"
        containerButtonsClass="col-sm-12 col-lg-4 container-button"
    >
      <template #desing>
        <div class="container">
          <div class="img-ctn">
            <img :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${formTab.carrier_code.toLowerCase()}.png`" alt="Fedex Logo" class="logo"/>
          </div>
          <div>
            <div class="content-caracteristics">
              <div class="row-carcteristics">
                <span class="label">Código</span>
                <span class="value">{{formTab.carrier_code}}</span>
              </div>
              <div class="row-carcteristics">
                <span class="label">Tipo de contrato</span>
                <span class="value">{{formTab.contract_type}}</span>
              </div>
              <div class="row-carcteristics">
                <span class="label">Integración</span>
                <span class="value">{{ formTab.dispatch_in }}</span>
              </div>
            </div>
            <div class="service-row">
              <span class="label">Servicios</span>
              <div class="service-contain">
                <span class="service" v-for="service in formTab.services" :key="service.code">{{ service.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </form-render>
    <b-button @click="ok" variant="warning" :disabled="loading">
      <b-spinner v-show="loading" small></b-spinner> Guardar
    </b-button>
  </b-modal>
</template>

<script>
import BaseServices from '@/store/services/index'
import {mapGetters} from 'vuex'
export default {
  name: 'ModalWarehouseCompanies',
  props: ['formTab', 'filter'],
  data() {
    return {
      form: {},
      fields: [],
      editing: false,
      keyFormRender: 0,
      baseService: new BaseServices(this),
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      courierCompanies: 'getCourierConfiguration'
    })
  },
  watch: {
    formTab() {
      this.initializeForm()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { name: 'desing', useSlot: true, containerClass: 'col-md-12 mb-2 container'},
        { fieldType: 'FieldSelect', name: 'bloked-service', label: 'Bloquear servicios', containerClass: 'col-12', clearable: true, multiple: true, specialName: true}
      ]
    },
    initializeForm() {
      const services = this.formTab.services.map(service => {
        return {
          id: service.code.toLowerCase(),
          text: service.name,
          name: `${service.name} - ${service.code}`,
          carrier_id: this.formTab.carrier_id
        }
      })
      this.fields[1].options = services
      this.editing = !!this.formTab.id
      if (this.editing) {
        const codesToFilter = this.formTab.blocked_services.split(' - ')
        const blocked_services = services.filter(item => codesToFilter.includes(item.id))
        this.form = {
          ...this.formTab,
          'bloked-service': blocked_services
        }
      } else {
        this.form = {}
      }
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderAccount.checkForm()
    },
    async onAccept(form) {
      this.loading = true
      const queryParams = [...form['bloked-service'].map(service => service.id)]
      const params = {
        carrier_id: form['bloked-service'][0]?.carrier_id || this.formTab.carrier_id,
        company_id: parseInt(this.$route.params.id)
      }
      try {
        const response = await this.baseService.callServiceArrayQueryParams('updateCourierConfiguration', queryParams, params)
        this.loading = false
        this.$bvModal.hide('ModalCourierConfigurationBlocked')
        this.$emit('filter')
        this.$success(this.$t('msg-exito-guardar'))
      } catch (error) {
        console.error('error', error)
        this.$alert(this.$t('msg-problema-guardar', { code: error.message }))
        this.loading = false
      }
    },
    onClose() {
      this.form = {}
      this.editing = false
      this.keyFormRender++
      this.codeForServices = [{}]
      this.showTemplate = false
    }
  }
}
</script>


<style scoped lang="scss">

.container{
  display: grid;
  grid-template-columns: 230px 1fr;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
}

.img-ctn {
  width: 200px;
}
.logo {
  max-width: 200px;
  object-fit: fill;
}

.content-caracteristics {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1rem;
}

.row-carcteristics {
  display: flex;
  flex-direction: column;
}
.service-row {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.label {
  font-weight: bold;
  font-size: 1rem;
}

.value {
  font-weight: normal;
}

.service-contain{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.3rem;
  font-size: 0.8rem;
  color: #000;
}

.service {
  background-color: #c5c5c54d;
  padding: 0.4rem;
  text-align: center;
  margin-right: 10px;
  border-radius: 5px; /* Ajustar según el diseño */
}
</style>
