<template>
<div>
  <b-card class="px-1">
    <div v-if="showFilters">
      <div class="button-open-modal p-1 ">
        <b-button variant="warning" class="push-right" @click="() => openModalAdd(null)" :disabled="loading.edit || disabledAddCurrier"><feather-icon icon="ArchiveIcon"/> Añadir courier</b-button>
      </div>
      <filter-swapper
        v-if="!loading.first"
        :controlHeightButtons="controlHeight"
        >
        <template #slot1>
          <form-render
            :key="keyFormRender"
            :fields="fields"
            :buttonSend="buttonSend"
            class="mb-2"
            :form.sync="formFilter"
            @send="filterList"
            containerButtonsClass="col-sm-12 col-md-8 col-lg-3 container-button mt-2"
          >
          <template #buttons>
            <b-button
              variant="outline-light"
              v-b-tooltip.hover
              title="Limpiar filtros"
              class="ml-2"
              @click="cleanFilter"
              ><feather-icon icon="RefreshCwIcon"
            /></b-button>
          </template>
          </form-render>
        </template>
      </filter-swapper>
    </div>
    
    <table-render
      id="table-configurations-couriers"
      :schema="schema"
      :rows="rows"
      :actions="actions"
      :loading="loading.couriersCompany"
      v-show="!loading.first"
    >
    </table-render>
    <b-skeleton
      height="40px"
      width="35%"
      class="mt-2 mb-2 spacing-label-field"
      v-show="loading.first"
    />
    <div class="table-render-skeleton" v-show="loading.first">
      <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="schema.length || 10"
        :table-props="{}"
      />
    </div>
  </b-card>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CourierConfiguration',
  props: ['openModalAdd', 'openModal'],
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      loading: {
        first: true,
        couriersCompany: false
      },
      formFilter: {},
      fields: [],
      pagination: {
        limit: 10,
        page: 1,
        total: 0
      },
      keyFormRender: 0,
      showFilters: false,
      disabledAddCurrier: true
    }
  },
  computed: {
    ...mapGetters({
      courierCompanies: 'getCourierConfiguration',
      carrierListByCompany: 'getCarrierListByCompany',
      companyInformation: 'getCompanyInformation',
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      this.specialConfig()
    },
    courierCompanies() {
      this.rows = this.formaterRows(this.courierCompanies)
      this.loading.first = false
      this.loading.couriersCompany = false
    },
    companyInformation() {
      this.showButon()
    },
    carrierListByCompany() {
      this.disabledAddCurrier = false
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        { key: 'logo', label: 'Courier', useDangerHTML: true },
        { key: 'carrier_code', label: 'Codígo' },
        { key: 'service', label: 'Servicios' },
        { key: 'contract_type', label: 'Tipo de contrato' },
        {key: 'actions', label: 'Acciones', class: ['text-center']}
      ]

      this.actions = [
        { name: 'eliminar', key: 'delete', icon: 'TrashIcon', color: 'danger', action: this.confirmRemoveCourierCompany }
      ]

      this.fields = [
        { fieldType: 'FieldInput', name: 'search_string', label: 'Nombre / Código', containerClass: 'col-sm-12 container-info col-md-3', placeholder : 'Ingrese nombre o código del courier' }
      ]
      if (!Object.keys(this.companyInformation).length) this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: { company_id: this.$route.params?.id }})
      if (this.mySession.id) this.specialConfig()
      this.getInitialData()
    },
    specialConfig () {
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) {
        this.schema.splice(this.schema.findIndex(field => field.key === 'service') + 1, 0, { key: 'blocked_services', label: 'Servicios bloqueados', class: ['text-center'] })
        this.schema.splice(this.schema.findIndex(field => field.key === 'service') + 3, 0, { key: 'integration', label: 'Integración' })
        this.actions.unshift({ name: 'editar', key: 'edit', icon: 'EditIcon', color: 'info', action: id => this.openModal(this.rows.find(row => row.id === id))})
        this.showFilters = true
      }
      this.showButon()
    },
    showButon() {
      if (['marketplace'].includes(this.$session.get('cas_user')?.role) && !this.companyInformation?.rows?.company.marketplace) {
        this.showFilters = true
      }
    },
    getInitialData(search) {
      this.loading.couriersCompany = true
      const queryParams = {
        exclude_flash : 0,
        limit: 99
      }
      const params = {
        company_id: parseInt(this.$route.params.id)
      }
      if (search) queryParams.text = search
      this.$store.dispatch('fetchService', { name: 'getCourierConfiguration', queryParams, params })
      this.$store.dispatch('fetchService', { name: 'getCarriersByCountriesPlatform', params: { country_id: 1 }, queryParams: { simplify: true } })
      this.$store.dispatch('fetchService', { name: 'getCarrierListByCompany', params })
    },
    formaterRows(rows) {
      return rows.map(courierCompanie => ({
        ...courierCompanie,
        logo: `<img src='https://storage.googleapis.com/enviame-misc/carriers/logos/color/${courierCompanie.carrier_code.toLowerCase()}.png' width="150"></img>`,
        service: courierCompanie.services.filter(service => !courierCompanie.blocked_services.includes(service.code)).map(service => service.name).join(' - '),
        blocked_services: courierCompanie.blocked_services.map(service => service).join(' - ') || '---',
        contract_type: courierCompanie.contract_type || 'No definido',
        integration: courierCompanie.dispatch_in || 'No Disponible',
        id: courierCompanie.carrier_company_id
      }))
    },
    filterList() {
      this.getInitialData(this.formFilter.search_string)
    },
    cleanFilter() {
      this.keyFormRender++
      this.formFilter = {}
      this.getInitialData()
    },
    confirmRemoveCourierCompany(_id) {
      this.$yesno(null, () => this.removeCourierConfig(_id), this.$t('msg-pregunta-eliminar-elemento'))
    },
    removeCourierConfig(id) {
      this.$store.dispatch('fetchService', {
        name: 'deleteCourierConfiguration',
        params: { carrier_company_id: id, company_id: parseInt(this.$route.params.id) },
        onSuccess: this.succesDeleteCourierCompany
      })
    },
    succesDeleteCourierCompany() {
      this.$success(this.$t('msg-exito-eliminar-elemento'))
      this.getInitialData()
    }
  }

}
</script>

<style>
.button-open-modal {
  top: 0rem !important;
  right: 0 !important;
  position: absolute !important;
}
</style>
